<template>
    <div class="nodata_no_list__OBtyg">
        <div class="nodata_img__2_JtJ">
            <div class="image_imageContainer__2dKjX image_cover__3B5D-" style="cursor: inherit">
                <div>
                    <img src="@/assets/images/static/welfare_img_03.png" decoding="async" class="welfare-img-02" />
                </div>
            </div>
        </div>
        <span>{{ tips }}</span>
    </div>
</template>
<script>
export default {
    props: {
        tips: {
            type: String,
            default: '暂无内容',
        },
    },
};
</script>
<style lang="scss" scoped>
.welfare-img-02 {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    filter: none;
    background-image: none;
}
</style>
