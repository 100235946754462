import request from '../http.js';
export default {
    /**
     * 获取首页banner
     * @param data
     * @param isLoad
     */
    getBannerList: (data) => {
        return request.Post('/api/bannerList', data);
    },
    /**
     * 获取滚动公告栏内容
     * @param data
     * @param isLoad
     */
    getHomeNotice: (data) => {
        return request.Post('/api/homenotice', data);
    },
    /**
     * 获取热门游戏
     * @param data
     * @param isLoad
     */
    getGameHotList: (data) => {
        return request.Get('/api/game/Hotlist', data, {isLoad:'load'});
    },
};
