export default {
    /**
     * 设置session信息
     * @param  {...any} item
     */
    setSession: function (name, obj, str) {
        if(str){
            sessionStorage[name] = obj;
        }else{
            sessionStorage[name] = JSON.stringify(obj);
        }
    },
    /**
     * 获取session信息
     * @param  {...any} item
     */
    getSession: function (name,str) {
        if(str){
            return sessionStorage[name];
        }else if(sessionStorage[name]){
            return JSON.parse(sessionStorage[name]);
        }else {
            return null;
        }
    },
    /**
     * 删除session信息
     * @param  {...any} item
     */
    removeSession: function (name) {
        sessionStorage.removeItem(name);
    },
    /**
     * 清除session信息
     * @param  {...any} item
     */
    clearSession: function () {
        sessionStorage.clear()
    },
    /**
     * 验证手机号
     * @param  {...any} item
     */
    verifyMobile: function (mobile) {
        return /^1[3-9]\d{9}$/.test(mobile);
    },
    /**
     * 验证邮箱
     * @param  {...any} item
     */
    verifyEmail: function (email) {
        return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email);
    },
    /**
     * 验证密码
     * @param  {...any} item
     */
    verifyPassword: function (email) {
        return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(email);
    },
    /**
     * 验证账号（4-12位字母数字）
     * @param  {...any} item
     */
    verifyUserName: function (userName) {
        return /^[a-zA-Z0-9_]{4,16}$/.test(userName);
    },
    /**
     * 判断是否登录
     * @param  {...any} item
     */
    isLogin: function (obj) {
        let token = sessionStorage.token;
        if(token){
            return true;
        }else{
            // console.log(router);
            return false;
        }
    },
};

