<template>
    <div class="bodyFooter footer_footerView__3Rvo5">
        <div class="footer_contentBox__YKvzZ">
            <div class="footer_logbox__2lO4o">
                <div class="footer_agent_item__pTRL0" v-for="(item, index) in footerAgentList" :key="index">
                    <div class="image_imageContainer__2dKjX image_cover__3B5D- footer_agentIcon__CBLHm" style="cursor: inherit">
                        <div>
                            <img alt="imageUrl" sizes="168px" :srcset="item.img" :src="item.img" decoding="async" class="footer-img" />
                        </div>
                    </div>
                    <div class="image_imageContainer__2dKjX image_cover__3B5D- footer_agentIcon__CBLHm" style="cursor: inherit">
                        <div>
                            <img
                                alt="afterImageUrl"
                                sizes="168px"
                                :srcset="item.actImg"
                                :src="item.actImg"
                                decoding="async"
                                class="footer-img"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_explanation__20z_J">
                <p>
                    {{ $store.state.systemApp.site_keyword }}拥有欧洲马耳他（MGA）和菲律宾政府竞猜委员会（PAGCOR）颁发的合法执照。
                    <br />
                    注册于英属维尔京群岛，是受国际行业协会认可的合法公司。进行注册并娱乐前，请确保您年满18周岁！
                </p>
            </div>
            <div class="footer_license_warp___wpzc">
                <ul class="footer_license_list__1A5TM">
                    <li class="footer_license_item__132eo">
                        <div class="footer_icon_group__Fqnq_" style="width: 165px; height: 34px">
                            <div class="footer_iconDefault__xN-8d"></div>
                            <div class="footer_iconHover__1fsn7"></div>
                        </div>
                        <span class="footer_license_name__1mH1b">马耳他牌照（MGA）认证</span>
                    </li>
                    <li class="footer_license_item__132eo">
                        <div class="footer_icon_group__Fqnq_" style="width: 171px; height: 41px">
                            <div class="footer_iconDefault__xN-8d"></div>
                            <div class="footer_iconHover__1fsn7"></div>
                        </div>
                        <span class="footer_license_name__1mH1b">英属维尔京群岛（BVI）认证</span>
                    </li>
                    <li class="footer_license_item__132eo">
                        <div class="footer_icon_group__Fqnq_" style="width: 138px; height: 41px">
                            <div class="footer_iconDefault__xN-8d"></div>
                            <div class="footer_iconHover__1fsn7"></div>
                        </div>
                        <span class="footer_license_name__1mH1b">菲律宾（PAGCOR）监督竞猜牌照</span>
                    </li>
                </ul>
            </div>
            <div class="footer_helper_warp__2g_rh">
                <ul class="footer_helper_list__1CUfq">
                    <li class="footer_helper_item__3xbYz" v-for="(item, index) in footerList" :key="index" @click="goPath(item)">
                        {{ item.name }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer_copyright__2pEc7">版权所有 ©2010-2022{{ $store.state.systemApp.site_keyword }} 保留所有权</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            footerList: [
                {
                    name: '新手帮助',
                    path: '/help/kh',
                },
                {
                    name: '竞猜责任',
                    path: '/help/kh',
                },
                {
                    name: '隐私保护',
                    path: '/help/kh',
                },
                {
                    name: '规则条款',
                    path: '/help/kh',
                },
                {
                    name: '联系我们',
                    path: '/help/kh',
                },
                {
                    name: '代理加盟',
                    path: '/agent',
                },
                {
                    name: 'APP下载',
                    path: '/download',
                },
            ],
            footerAgentList: [
                { img: require('@/assets/images/static/footer_01.png'), actImg: require('@/assets/images/static/footer_01_active.png') },
                { img: require('@/assets/images/static/footer_02.png'), actImg: require('@/assets/images/static/footer_02_active.png') },
                { img: require('@/assets/images/static/footer_03.png'), actImg: require('@/assets/images/static/footer_03_active.png') },
                { img: require('@/assets/images/static/footer_04.png'), actImg: require('@/assets/images/static/footer_04_active.png') },
                { img: require('@/assets/images/static/footer_05.png'), actImg: require('@/assets/images/static/footer_05_active.png') },
                { img: require('@/assets/images/static/footer_06.png'), actImg: require('@/assets/images/static/footer_06_active.png') },
                { img: require('@/assets/images/static/footer_07.png'), actImg: require('@/assets/images/static/footer_07_active.png') },
                { img: require('@/assets/images/static/footer_08.png'), actImg: require('@/assets/images/static/footer_08_active.png') },
                { img: require('@/assets/images/static/footer_09.png'), actImg: require('@/assets/images/static/footer_09_active.png') },
                { img: require('@/assets/images/static/footer_10.png'), actImg: require('@/assets/images/static/footer_10_active.png') },
                { img: require('@/assets/images/static/footer_11.png'), actImg: require('@/assets/images/static/footer_11_active.png') },
                { img: require('@/assets/images/static/footer_12.png'), actImg: require('@/assets/images/static/footer_12_active.png') },
                { img: require('@/assets/images/static/footer_13.png'), actImg: require('@/assets/images/static/footer_13_active.png') },
                { img: require('@/assets/images/static/footer_14.png'), actImg: require('@/assets/images/static/footer_14_active.png') },
            ],
        };
    },
    methods: {
        goPath(item) {
            let path = '';
            if (item.name == '联系我们') {
                path = this.$store.state.servicerUrl;
            } else if (item.name == 'APP下载') {
                path = item.path;
            }
            if (path) {
                this.$router.jumpRoute(path);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.footer-img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}
</style>
