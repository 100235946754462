import request from '../http.js';
export default {
    /**
     * 获取余额
     * @param data
     * @param isLoad
     */
    getBalance: (data) => {
        return request.Post('/api/balance', data);
    },
    /**
     * 获取用户信息
     * @param data
     * @param isLoad
     */
    getUserInfo: (data) => {
        return request.Post('/api/user', data);
    },
    /**
     * 获取钱包明细里面的游戏金额
     * @param data
     * @param isLoad
     */
    getBalanceList2: (data) => {
        return request.Post('/api/balancelist2', data);
    },
    /**
     * 一键回收金额
     * @param data
     * @param isLoad
     */
    recovery: (data) => {
        return request.Post('/api/transall', data);
    },
    /**
     * 基础资料提交
     * @param data
     * @param isLoad
     */
    updateUserInfo: (data) => {
        return request.Post('/api/updateuserinfo', data);
    },
    /**
     * 更新手机
     * @param data
     * @param isLoad
     */
    updateMobile: (data) => {
        return request.Post('/api/updateMoible', data);
    },
    /**
     * 修改密码
     * @param data
     * @param isLoad
     */
    editPassword: (data) => {
        return request.Post('/api/editPassword', data);
    },
    /**
     * 修改支付密码
     * @param data
     * @param isLoad
     */
    editPayPassword: (data) => {
        return request.Post('/api/editPayPassword', data);
    },
    /**
     * 获取vip等级
     * @param data
     * @param isLoad
     */
    getVipGrade: (data) => {
        return request.Post('/api/upuserlevelNew', data);
    },
    /**
     * 获取所有vip等级以及返水比例
     * @param data
     * @param isLoad
     */
    getUserVip: (data) => {
        return request.Post('/api/uservip', data);
    },
    /**
     * 福利中心
     * @param data
     * @param isLoad
     */
    getWelfare: (data) => {
        return request.Post('/api/getfanshui', data, { isLoad: 'load' });
    },
    /**
     * 交易记录
     * @param data
     * @param isLoad
     */
    getTransrecord: (data) => {
        return request.Post('/api/gettransrecord', data, { isLoad: 'load' });
    },
    /**
     * 投注记录
     * @param data
     * @param isLoad
     */
    getBetrecord: (data) => {
        return request.Post('/api/betrecord', data, { isLoad: 'load' });
    },
    /**
     * 消息中心
     * @param data
     * @param isLoad
     */
    getMessage: (data) => {
        return request.Post('/api/showmessage', data, { isLoad: 'load' });
    },
    /**
     * 添加银行卡
     * @param data
     * @param isLoad
     */
    bindCard: (data) => {
        return request.Post('/api/bindcard', data);
    },
    /**
     * 获取银行卡列表
     * @param data
     * @param isLoad
     */
    getBankList: (data) => {
        return request.Post('/api/banklist', data);
    },
    /**
     * 获取兑换记录
     * @param data
     * @param isLoad
     */
    getClaimRecord: (data) => {
        return request.Post('/api/claimRecord', data);
    },
    /**
     * 更改头像
     * @param data
     * @param isLoad
     */
    uploadImg: (data) => {
        return request.Post('/api/uploadimg', data, { isLoad: 'load' });
    },
    /**
     * 获取支付方式列表
     * @param data
     * @param isLoad
     */
    getPayRange: (data) => {
        return request.Post('/api/getPayRange', data);
    },
    /**
     * 提交支付
     * @param data
     * @param isLoad
     */
    recharge: (data) => {
        return request.Post('/api/recharge', data);
    },
    /**
     * 获取支付信息
     * @param data
     * @param isLoad
     */
    getPayDetail: (data) => {
        return request.Post('/api/getPayDetail', data);
    },
    /**
     * 获取支付信息
     * @param data
     * @param isLoad
     */
    cancelRecharge: (data) => {
        return request.Post('/api/cancelRecharge', data);
    },
    /**
     * 获取我的银行卡
     * @param data
     * @param isLoad
     */
    getcard: (data) => {
        return request.Post('/api/getcard', data);
    },
    /**
     * 提现
     * @param data
     * @param isLoad
     */
    withdraw: (data) => {
        return request.Post('/api/withdraw', data);
    },
    /**
     * 解除绑定银行卡
     * @param data
     * @param isLoad
     */
    delcard: (data) => {
        return request.Post('/api/delcard', data);
    },
    /**
     * 获取验证码
     * @param data
     * @param isLoad
     */
    getCode: (data) => {
        return request.Post('/api/getCode', data);
    },
    /**
     * 校验验证码
     * @param data
     * @param isLoad
     */
    checkCode: (data) => {
        return request.Post('/api/checkCode', data);
    },
    /**
     * 校验是否设置了二级密码
     * @param data
     * @param isLoad
     */
    verifyPassword: (data) => {
        return request.Post('/api/verifyPassword', data);
    },
};

