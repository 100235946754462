// /*
//  *注册公共组件
//  */
//公共头部
import navHeader from '@/components/common/navHeader.vue';
//公共底部
import navFooter from '@/components/common/navFooter.vue';
//用户左侧列表
import navList from '@/components/common/navList.vue';
//暂无内容
import noData from '@/components/common/noData.vue';
//登录弹窗提示
import loginTips from '@/components/common/loginTips.vue';

export default (Vue) => {
    Vue.component('navHeader', navHeader);
    Vue.component('navFooter', navFooter);
    Vue.component('navList', navList);
    Vue.component('noData', noData);
    Vue.component('loginTips', loginTips);
};
