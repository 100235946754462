<template>
    <div class="advert">
        <!-- <div class="floatWindows_floatWindows__1fYFY">
            <div
                class="floatWindows_floatItem__tTvic floatWindows_floatItem-0__28DBK"
                style="transform: translate3d(0px, 0px, 1px)"
                v-if="isShow0"
            >
                <div class="floatWindows_content__3g8ee">
                    <span class="floatWindows_close__2pA2W" id="float-close-wrap-0" @click="isShow0 = false"
                        ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" id="float-close-0">
                            <g fill="none" fill-rule="evenodd" id="float-close-g1-0">
                                <g fill="#B8BDCD" fill-rule="nonzero" id="float-close-g2-0">
                                    <g id="float-close-g3-0">
                                        <path
                                            d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zm0 1C3.239 1 1 3.239 1 6s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5zM4.232 3.525L6 5.293l1.768-1.768c.195-.195.511-.195.707 0 .195.195.195.512 0 .707L6.707 6l1.768 1.768c.195.195.195.511 0 .707-.196.195-.512.195-.707 0L5.999 6.706 4.232 8.475c-.195.195-.512.195-.707 0-.195-.196-.195-.512 0-.707l1.767-1.769-1.767-1.767c-.195-.195-.195-.512 0-.707.195-.195.512-.195.707 0z"
                                            transform="translate(-75 -48) translate(75 48)"
                                            id="float-close-path-0"
                                        ></path>
                                    </g>
                                </g>
                            </g></svg></span
                    ><img v-lazy="configs.api.local_domain + '/img/advert_01.01adb742.gif'" class="floatWindows_img__vkHGq" />
                </div>
            </div>
            <div
                class="floatWindows_floatItem__tTvic floatWindows_floatItem-1__1HetC"
                style="transform: translate3d(0px, 0px, 1px)"
                v-if="isShow1"
            >
                <div class="floatWindows_content__3g8ee">
                    <span class="floatWindows_close__2pA2W" id="float-close-wrap-1" @click="isShow1 = false"
                        ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" id="float-close-1">
                            <g fill="none" fill-rule="evenodd" id="float-close-g1-1">
                                <g fill="#B8BDCD" fill-rule="nonzero" id="float-close-g2-1">
                                    <g id="float-close-g3-1">
                                        <path
                                            d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zm0 1C3.239 1 1 3.239 1 6s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5zM4.232 3.525L6 5.293l1.768-1.768c.195-.195.511-.195.707 0 .195.195.195.512 0 .707L6.707 6l1.768 1.768c.195.195.195.511 0 .707-.196.195-.512.195-.707 0L5.999 6.706 4.232 8.475c-.195.195-.512.195-.707 0-.195-.196-.195-.512 0-.707l1.767-1.769-1.767-1.767c-.195-.195-.195-.512 0-.707.195-.195.512-.195.707 0z"
                                            transform="translate(-75 -48) translate(75 48)"
                                            id="float-close-path-1"
                                        ></path>
                                    </g>
                                </g>
                            </g></svg></span
                    ><img v-lazy="configs.api.local_domain + '/img/advert_02.c3f7b95a.gif'" class="floatWindows_img__vkHGq" />
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShow0: true,
            isShow1: true,
        };
    },
};
</script>
