<template>
    <div v-if="modal10">
        <Modal :title="title" v-model="isShow" width="480" class-name="tips-modal" @on-cancel="closeTips()">
            <div class="tips-box">
                <div class="text">{{ text }}</div>
                <div class="btn">
                    <Button type="primary" long @click="$router.jumpRoute('/user/login')">确定</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShow: false,
        };
    },
    props: {
        modal10: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '温馨提示',
        },
        text: {
            type: String,
            default: '请先登录账号，才能了解更多',
        },
    },
    watch: {
        modal10(val) {
            this.isShow = val;
        },
    },
    methods: {
        closeTips() {
            this.$emit('closeTips');
        },
    },
};
</script>
<style lang="scss" scoped>
.tips-box {
    text-align: center;
    .text {
        font-size: 14px;
        color: #303442;
        line-height: 1.6;
        overflow: auto;
        margin: 24px;
    }
    .btn {
        padding: 0 80px 32px;
    }
}
</style>
