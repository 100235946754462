import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import store from '@/store/index';
import bus from '@/bus.js';

Vue.use(VueRouter);
/**
 * 跳转路由
 * @param  {String}  path      跳转地址
 * @param  {String}  type      类型
 * @param  {Object}  obj       需要传递的参数 eg： {id：1，title：name}
 */
VueRouter.prototype.jumpRoute = function (path, obj, type) {
    let types = type || 'push';
    if (path) {
        //判断是否客服链接
        if(path == store.state.servicerUrl){
            bus.$emit('showKfTips');
            return false;
        }
        //判断是否外链
        if (path.indexOf('http') != -1) {
            // window.open(path, '_blank');
            let tempALink = document.createElement("a");
            tempALink.setAttribute("target", "_blank");
            tempALink.setAttribute("id", "openWin");
            tempALink.setAttribute("href", path);
            document.body.appendChild(tempALink);
            document.getElementById("openWin").click();
            document.body.removeChild(tempALink);
        } else {
            this[types]({
                path: path,
                query: { ...(obj || {}) },
            });
        }
    } else {
        this.go(-1);
    }
};

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/user/login', //登录
        name: 'Login',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/user/login.vue'),
        meta: {
            layout: 'login',
        },
    },
    {
        path: '/user/register', //注册
        name: 'Login',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/user/register.vue'),
        meta: {
            layout: 'login',
        },
    },
    {
        path: '/game/sport', //体育
        name: 'Sport',
        component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Sport.vue'),
    },
    {
        path: '/game/realbet', //真人
        name: 'Realbet',
        component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Realbet.vue'),
    },
    {
        path: '/game/joker', //棋牌
        name: 'Joker',
        component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Joker.vue'),
    },
    {
        path: '/game/gaming', //电竞
        name: 'Gaming',
        component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Gaming.vue'),
    },
    {
        path: '/game/lottery', //彩票
        name: 'Lottery',
        component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Lottery.vue'),
    },
    {
        path: '/game/concise', //电子
        name: 'Concise',
        component: () => import(/*webpackChunkName:"game"*/ '@/views/game/Concise.vue'),
    },
    {
        path: '/agent', //合营
        name: 'Agent',
        component: () => import(/*webpackChunkName:"operate"*/ '@/views/navUse/Agent.vue'),
        meta: {
            layout: 'noFooter',
        },
    },
    {
        path: '/activity/discount', //优惠
        name: 'Discount',
        component: () => import(/*webpackChunkName:"operate"*/ '@/views/activity/Discount.vue'),
    },
    {
        path: '/activity/activityLandPage/:id', //高额返水
        name: 'ActivityLandPage',
        component: () => import(/*webpackChunkName:"operate"*/ '@/views/activity/ActivityLandPage.vue'),
    },
    {
        path: '/activity/bettingGift', //豪礼盛宴
        name: 'BettingGift',
        component: () => import(/*webpackChunkName:"operate"*/ '@/views/activity/BettingGift.vue'),
    },
    {
        path: '/sponsor/rmcf', //赞助
        name: 'Rmcf',
        component: () => import(/*webpackChunkName:"operate"*/ '@/views/navUse/sponsor/Rmcf.vue'),
        meta: {
            layout: 'noFooter',
        },
    },
    {
        path: '/download', //APP下载
        name: 'Download',
        component: () => import(/*webpackChunkName:"operate"*/ '@/views/navUse/Download.vue'),
        meta: {
            layout: 'noFooter',
        },
    },
    {
        path: '/mine/userInfo', //个人资料
        name: 'UserInfo',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/UserInfo.vue'),
    },
    {
        path: '/mine/vip', //VIP
        name: 'Vip',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/Vip.vue'),
    },
    {
        path: '/mine/vip/details', //VIP
        name: 'VipDetails',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/VipDetails.vue'),
    },
    {
        path: '/mine/record/welfare', //福利中心
        name: 'Welfare',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/record/Welfare.vue'),
    },
    {
        path: '/mine/finance/wallet', //我的钱包
        name: 'Wallet',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/Wallet.vue'),
    },
    {
        path: '/mine/record/transRecord', //交易记录
        name: 'TransRecord',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/record/TransRecord.vue'),
    },
    {
        path: '/mine/record/betRecord', //投注记录
        name: 'BetRecord',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/record/BetRecord.vue'),
    },
    {
        path: '/mine/record/giftRecord', //兑奖记录
        name: 'GiftRecord',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/record/GiftRecord.vue'),
    },
    {
        path: '/mine/mail', //消息中心
        name: 'Mail',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/Mail.vue'),
    },
    {
        path: '/mine/feedback', //意见反馈
        name: 'Feedback',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/Feedback.vue'),
    },
    {
        path: '/mine/finance/deposit', //存款
        name: 'Deposit',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/Deposit.vue'),
    },
    {
        path: '/mine/finance/transfer', //转账
        name: 'Transfer',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/Transfer.vue'),
    },
    {
        path: '/mine/finance/withdrawal', //取款
        name: 'Withdrawal',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/Withdrawal.vue'),
    },
    {
        path: '/mine/finance/payOrder', //支付信息
        name: 'PayOrder',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/finance/PayOrder.vue'),
    },
    {
        path: '/help/kh', //开户
        name: 'Kh',
        component: () => import(/*webpackChunkName:"help"*/ '@/views/help/Kh.vue'),
    },
    {
        path: '/mine/addBankCard', //添加银行卡
        name: 'AddBankCard',
        component: () => import(/*webpackChunkName:"mine"*/ '@/views/mine/AddBankCard.vue'),
    },
    {
        path: '/mine/finance/payMent', //登录
        name: 'PayMent',
        component: () => import(/*webpackChunkName:"user"*/ '@/views/mine/finance/PayMent.vue'),
        meta: {
            layout: 'login',
        },
    },
    // {
    //     path: '/humanAffairs', //人事登记（加入人才库）
    //     name: 'humanAffairs',
    //     component: () => import(/*webpackChunkName:"suer"*/ '../components/newPeople/humanManage/humanAffairs.vue'),
    //     meta: {
    //         requireAuth: true,
    //     },
    // },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// 验证是否需要登录
router.beforeEach((to, from, next) => {
    //判断路由地址是否包含token
    // if (to.query.token) {
    //     sessionStorage.token = to.query.token;
    // }
    if (to.matched.some((r) => r.meta.requireAuth)) {
        if (sessionStorage.getItem('token')) {
            next();
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath,
                },
            });
        }
    } else {
        next();
    }
    next();
});
router.afterEach(() => {
    window.scrollTo(0, 0);
});

export default router;
