<template>
    <div class="navList_navListView__dFHot">
        <!--头像-->
        <div class="navList_userInfoView__R5_Sd">
            <div class="headerImage_headerImageView__iIh8u" @click="modal10 = true">
                <div class="navList_avatarIcon__1NewF">
                    <img v-if="$store.state.userInfo.avatar" :src="$store.state.userInfo.avatar" alt="avatarIcon" />
                    <img v-else src="@/assets/images/static/header_img_01.png" alt="avatarIcon" />
                </div>
            </div>
            <div class="navList_nameView__1eDGA">
                <div class="navList_nameText__2HU2S">{{ $store.state.userInfo.username }}</div>
                <img
                    v-if="$store.state.userInfo.vipname"
                    :src="configs.api.local_domain + $store.state.userInfo.vipname"
                    class="navList_vipIcon__2kfeA"
                    alt="vip"
                />
                <img v-else src="@/assets/images/static/mine_left_icon_01.png" class="navList_vipIcon__2kfeA" alt="vip" />
            </div>
            <div class="navList_titleText__39fQq">
                加入{{ $store.state.systemApp.site_keyword }}体育第{{ $store.state.userInfo.joinday }}天
            </div>
        </div>
        <!--存款、转账、取款-->
        <div class="navList_financeView__T0AJu">
            <li
                class="navList_itemView__3blT6"
                :class="{ navList_activeView__3LxLO: item.path == $route.path }"
                @click="$router.jumpRoute(item.path)"
                v-for="(item, index) in financeList"
                :key="index"
            >
                <div class="navList_iconView__3Yi15">
                    <img :src="item.img" alt="icon" />
                    <img :src="item.actImg" alt="icon" />
                </div>
                <div>{{ item.name }}</div>
            </li>
        </div>
        <!--个人资料-->
        <ul class="navList_navView__1fqXR" v-for="(item, index) in navList" :key="index">
            <li
                :class="{ navList_activeView__3LxLO: kItem.path == $route.path }"
                v-for="(kItem, kIndex) in item"
                :key="kIndex"
                @click="$router.jumpRoute(kItem.path)"
            >
                <img :src="kItem.img" alt="icon" />
                <div>{{ kItem.name }}</div>
            </li>
        </ul>
        <!--我的头像-->
        <Modal title="我的头像" v-model="modal10" width="480" class-name="vertical-center-modal">
            <div class="preview">
                <div class="vip-bg">
                    <img :src="headerImgList[headerActIndex].img" alt="" class="img-preview" />
                </div>
            </div>
            <ul class="photo-list">
                <li
                    @click="headerActIndex = index"
                    v-for="(item, index) in headerImgList"
                    :key="index"
                    :class="{ 'active-view': index === headerActIndex }"
                >
                    <div class="img-view">
                        <img :src="item.img" alt="" />
                    </div>
                </li>
                <!-- <li>
                    <Upload :action="configs.api.local_domain + '/api/uploadimg'">
                        <Button icon="ios-cloud-upload-outline">上传</Button>
                    </Upload>
                </li> -->
            </ul>
            <div class="footer-view">
                <div class="footer-view-button" @click="uploadImg()">
                    <span>完成</span>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
export default {
    name: 'navList',
    data() {
        return {
            modal10: false,
            financeList: [
                {
                    name: '存款',
                    img: require('@/assets/images/static/mine_left_ck.png'),
                    actImg: require('@/assets/images/static/mine_left_ck_act.png'),
                    path: '/mine/finance/deposit',
                    key: 101,
                },
                {
                    name: '转账',
                    img: require('@/assets/images/static/mine_left_zz.png'),
                    actImg: require('@/assets/images/static/mine_left_zz_act.png'),
                    path: '/mine/finance/transfer',
                    key: 102,
                },
                {
                    name: '取款',
                    img: require('@/assets/images/static/mine_left_qk.png'),
                    actImg: require('@/assets/images/static/mine_left_qk_act.png'),
                    path: '/mine/finance/withdrawal',
                    key: 103,
                },
            ],
            navList: [
                [
                    {
                        name: '个人资料',
                        img: require('@/assets/images/static/mine_left_icon_02.png'),
                        path: '/mine/userInfo',
                        key: 1,
                    },
                    {
                        name: 'VIP特权',
                        img: require('@/assets/images/static/mine_left_icon_03.png'),
                        path: '/mine/vip',
                        key: 2,
                    },
                    {
                        name: '福利中心',
                        img: require('@/assets/images/static/mine_left_icon_04.png'),
                        path: '/mine/record/welfare',
                        key: 3,
                    },
                ],
                [
                    {
                        name: '我的钱包',
                        img: require('@/assets/images/static/mine_left_icon_05.png'),
                        path: '/mine/finance/wallet',
                        key: 4,
                    },
                    {
                        name: '交易记录',
                        img: require('@/assets/images/static/mine_left_icon_06.png'),
                        path: '/mine/record/transRecord',
                        key: 5,
                    },
                    {
                        name: '投注记录',
                        img: require('@/assets/images/static/mine_left_icon_07.png'),
                        path: '/mine/record/betRecord',
                        key: 6,
                    },
                ],
                [
                    {
                        name: '兑奖记录',
                        img: require('@/assets/images/static/mine_left_icon_08.png'),
                        path: '/mine/record/giftRecord',
                        key: 7,
                    },
                    {
                        name: '消息中心',
                        img: require('@/assets/images/static/mine_left_icon_09.png'),
                        path: '/mine/mail',
                        key: 8,
                    },
                    {
                        name: '意见反馈',
                        img: require('@/assets/images/static/mine_left_icon_10.png'),
                        path: '/mine/feedback',
                        key: 9,
                    },
                ],
            ],
            headerImgList: [
                {
                    img: require('@/assets/images/static/header_img_01.png'),
                },
                {
                    img: require('@/assets/images/static/header_img_02.png'),
                },
                {
                    img: require('@/assets/images/static/header_img_03.png'),
                },
                {
                    img: require('@/assets/images/static/header_img_04.png'),
                },
                {
                    img: require('@/assets/images/static/header_img_05.png'),
                },
                {
                    img: require('@/assets/images/static/header_img_06.png'),
                },
            ],
            headerActIndex: 0,
        };
    },
    created() {
        // console.log(this.$route.path);
        //获取用户信息
        this.$store.dispatch('getUserInfo');
    },
    methods: {
        uploadImg() {
            let formData = new FormData();
            formData.set('file', this.headerImgList[this.headerActIndex].img);
            formData.set('Status', 0);
            this.$request.uploadImg(formData).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    console.log(data);
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/static/mine/390476c67171a387965c.css';
.preview {
    margin: 40px auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    .vip-bg {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .img-preview {
        display: block;
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }
}
.photo-list {
    padding: 0 32px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
        border-radius: 50%;
        cursor: pointer;
        margin-bottom: 16px;
        margin-left: 6px;
        :nth-of-type(6n + 1) {
            margin-left: 0;
        }
    }
    .active-view {
        .img-view {
            border: 2px solid #179cff;
            position: relative;
        }
    }
    .img-view {
        display: block;
        border-radius: 50%;
        border: 2px solid #fff;
        box-sizing: border-box;
        width: 64px;
        height: 64px;
        &:hover {
            border: 2px solid #179cff;
            position: relative;
        }
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
}
.footer-view {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    padding-bottom: 29px;
    &-button {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        width: 320px;
        margin-top: 24px;
        background-image: linear-gradient(90deg, #45b0ff, #179cff);
        opacity: 1;
        -webkit-justify-content: center;
        justify-content: center;
        height: 40px;
        cursor: pointer;
        background-size: 100% 100%;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        border-radius: 4px;
        span {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            color: #fff;
            -webkit-transition: color 0.3s;
            transition: color 0.3s;
        }
    }
}
</style>
