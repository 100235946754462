<template>
    <div id="app">
        <!-- <nav>
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
        </nav> -->
        <component :is="layout">
            <router-view v-if="isRouterAlive"></router-view>
        </component>
        <!--系统维护提示-->
        <div class="maintain" v-if="$store.state.systemApp.site_state == 2">
            <p>{{ $store.state.systemApp.repair_tips }}</p>
        </div>
        <!--自定义组件、内容-->
        <a id="captchaButton" ref="captchaButton" class="captchaButton">点击弹出验证窗口</a>
        <!--自定义组件、内容-->
        <!--客服弹窗提示-->
        <Modal v-model="isShow" width="480" :closable="false" class-name="kefu-modal">
            <div class="kefu-tips">
                <div class="customerModal_container__20aZL">
                    <h3>安全提示</h3>
                    <div class="customerModal_tips__nnWOp customerModal_red__9iE3n">
                        在您没有索取存款卡号的情况下，客服不会主动给会员卡号引导会员存款。
                    </div>
                    <div class="customerModal_tips__nnWOp">
                        如果客服主动要求将资金转入某张银行卡，<span class="customerModal_red__9iE3n">请勿进行资金交易</span
                        >，否则引发的不到账等情况，概不负责。
                    </div>
                    <div class="customerModal_button__3wtMu" @click="openKefu()">我已了解</div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import bus from './bus.js';
//初始化凯格行为验证码
kg.captcha({
    // 绑定弹窗按钮
    button: '#captchaButton',
    // 验证成功事务处理
    success: function (e) {
        // 验证成功，直接提交表单
        // form1.submit();
        // store.commit('setSystemApp', true);
        bus.$emit('send', true);
        // setTimeout(() => {
        //     kg.reload(kg.param);
        // }, 300);
    },
    // 验证失败事务处理
    failure: function (e) {
        // console.log(e);
        // store.commit('setSystemApp', false);
        bus.$emit('send', false);
    },
    // 点击刷新按钮时触发
    refresh: function (e) {
        // console.log(e);
        // store.commit('setSystemApp', false);
        bus.$emit('send', false);
    },
});
export default {
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            default_layout: 'default', //设置layout
            isRouterAlive: true,
            isShow: false,
        };
    },
    created() {
        if (this.$route.query.token) {
            sessionStorage.token = this.$route.query.token;
        }
        //判断、获取客服链接
        let servicerUrl = this.$store.state.servicerUrl;
        if (!servicerUrl) {
            this.$store.dispatch('getServicerUrl');
        }
        //获取余额
        if (sessionStorage.token) {
            this.$store.dispatch('getBalance');
        }
        //判断、获取所有游戏
        let gameTypeList = this.$store.state.gameTypeList;
        if (gameTypeList[0].list.length <= 0) {
            gameTypeList.map((item) => {
                this.$store.dispatch('getGameTypeList', item.type);
            });
        }
        //获取系统信息
        this.getSystemApp();
    },
    mounted() {
        //验证码事件监听
        bus.$on('sendVerify', () => {
            kg.reload(kg.param);
            this.$refs.captchaButton.dispatchEvent(new MouseEvent('click'));
        });
        bus.$on('showKfTips', () => {
            this.isShow = true;
        });
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || this.default_layout) + '-layout';
        },
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
        getSystemApp() {
            this.$request.getSystemApp().then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    if (data.data.title) {
                        document.title = data.data.title;
                    }
                    this.$store.commit('setSystemApp', data.data);
                }
            });
        },
        openKefu() {
            let path = this.$store.state.servicerUrl;
            this.isShow = false;
            window.open(path, '_blank');
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/static/ff79e451471de2ac06c7.css';
@import '@/assets/css/static/046231bb46ba7bde7c18.css';
@import '@/assets/css/static/8a8ed63fe960a25312cd.css';
@import '@/assets/css/static/db2a65cabf7656de8f10.css';
@import '@/assets/css/app.scss';
.maintain {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    padding: 100px;
    color: #ffffff;
    font-size: 26px;
    background: black;
    z-index: 999999;
}
.captchaButton {
    display: none;
}
.kefu-modal {
    .ivu-modal-body {
        padding: 0;
    }
}
</style>
