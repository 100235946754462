import request from '../http.js';
export default {
    /**
     * 获取活动类型
     * @param data
     * @param isLoad
     */
    getActivityType: (data) => {
        return request.Post('/api/activitytype', data);
    },
    /**
     * 获取活动列表
     * @param data
     * @param isLoad
     */
    getActivityList: (data) => {
        return request.Post('/api/activitylist', data);
    },
    /**
     * 获取活动详情
     * @param data
     * @param isLoad
     */
    getActivityDetail: (data) => {
        return request.Post('/api/activitydeatil', data);
    },
    /**
     * 申请活动
     * @param data
     * @param isLoad
     */
    doactivityApply: (data) => {
        return request.Post('/api/doactivityapply', data);
    },
    /**
     * 申请活动-记录
     * @param data
     * @param isLoad
     */
    activityApplyLog: (data) => {
        return request.Post('/api/activityApplyLog', data);
    },
    /**
     * 获取红包列表
     * @param data
     * @param isLoad
     */
    getRedPacket: (data) => {
        return request.Post('/api/redpacket', data);
    },
    /**
     * 兑换红包
     * @param data
     * @param isLoad
     */
    douserredPacket: (data) => {
        return request.Post('/api/douserredpacket', data);
    },
    /**
     * 获取赞助列表
     * @param data
     * @param isLoad
     */
    getSponsor: (data) => {
        return request.Post('/api/sponsor', data);
    },
    /**
     * 获取赞助详情
     * @param data
     * @param isLoad
     */
    getSponsorDetail: (data) => {
        return request.Post('/api/sponsordeatil', data);
    },
};
